import { Controller } from "@hotwired/stimulus"
import { get } from "@rails/request.js"

// Connects to data-controller="medication"
export default class extends Controller {

  static targets = [ 
    "dosageSpinner", 
    "qtySpinner",
    "formSpinner",
    "dosageInput",
    "quantityInput",
    "formInput",
    "nameInput",
    "customQuantityInput",
  ];

  async change(event) {
    const name = this.nameInputTarget.value
    const form = this.formInputTarget.value
    const dosage = this.dosageInputTarget.value
    // let form = event.target.selectedOptions[0].value
    // console.log(event.target.id)
    switch (event.target.id) {
      case 'medication_form':
        this.dosageSpinnerTarget.classList.toggle('d-none')
        this.dosageInputTarget.toggleAttribute('disabled')
        this.qtySpinnerTarget.classList.toggle('d-none')
        this.quantityInputTarget.toggleAttribute('disabled')
        const response1 = await get(
          `/medication_update/?search=${name}&form=${form}&type=form`, 
          {
            responseKind: "turbo-stream"
          }
        );
        if (response1.ok) {
          this.dosageSpinnerTarget.classList.toggle('d-none')
          this.dosageInputTarget.toggleAttribute('disabled')
          this.qtySpinnerTarget.classList.toggle('d-none')
          this.quantityInputTarget.toggleAttribute('disabled')
        };
        break;
      case 'medication_strength':
        this.qtySpinnerTarget.classList.toggle('d-none')
        this.quantityInputTarget.toggleAttribute('disabled')
        const response2 = await get(
          `/medication_update/?search=${name}&form=${form}&strength=${dosage}&type=strength`, 
          {
            responseKind: "turbo-stream"
          }
        );
        if (response2.ok) {
          this.qtySpinnerTarget.classList.toggle('d-none')
          this.quantityInputTarget.toggleAttribute('disabled')
        };
        break;
    }
  };

  showTextField(event) {
    if (event.target.value === "Other") {
      this.customQuantityInputTarget.classList.toggle('d-none')
    }
  }

}
